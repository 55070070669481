<template>
  <NuxtLink
    v-if="slug"
    ref="jobTeaserRef"
    :to="{
      name: 'job',
      params: {
        id: slug,
      },
    }"
    @click="
      placement &&
        dataLayer.job.listClick(
          job,
          placement,
          isJobOfTheDay
            ? 'Job of the day'
            : isHighlighted
            ? 'Highlighted'
            : 'Normal'
        )
    "
  >
    <div
      class="grid grid-cols-[56px_1fr] md:grid-cols-[80px_1fr] gap-3 items-center h-28 p-2 relative border-b border-gray-300"
      :class="{
        'ring-1 ring-blue ring-inset bg-lightgray': isHighlighted,
        'md:gap-6 md:px-6 md:h-32': big,
      }"
    >
      <div class="h-14 md:h-20 border rounded bg-white">
        <NuxtImg
          v-if="advertiser?.logoLink"
          :src="advertiser?.logoLink"
          loading="lazy"
          class="h-14 md:h-20 p-1 object-contain"
          alt="logo"
          width="80"
        />
      </div>
      <div
        class="grid gap-2"
        :class="{ 'mb-4': partner?.logoLink, 'md:gap-3 md:mb-0': big }"
      >
        <span
          v-if="isJobOfTheDay"
          class="bg-blue uppercase text-[11px] font-bold text-white tracking-widest rounded w-fit py-0.5 px-1.5"
          :class="{
            'md:text-xs': big,
            'leading-[13px]': false,
            'leading-[14px]': !false,
          }"
        >
          {{ $t('jobPlatform.jobOfTheDay') }}
        </span>
        <h6
          class="font-sans font-bold line-clamp-2 text-sm/4"
          :class="{ 'md:text-lg/5': big }"
        >
          {{ title }}
        </h6>
        <p
          class="text-gray-500 text-xs font-sans overflow-hidden whitespace-nowrap text-ellipsis"
        >
          <span>{{ advertiser?.jobPlace }}</span>
          <span
            v-if="advertiser?.jobPlace"
            class="bg-gray-500 w-1 h-1 rounded-full mx-1.5 inline-block align-middle"
          />
          <span>{{ location?.name }}</span>
        </p>
      </div>
      <span
        v-if="partner && partner.id === GENITOR_PARTNER_ID"
        class="absolute right-0 bottom-0 h-14 w-[208px] genitor-commercial-triangle"
        :class="{ 'md:h-20 md:w-[300px]': big }"
      />
      <NuxtImg
        v-if="partner?.logoLink"
        :src="partner.logoLink"
        class="m-2 absolute right-0 bottom-0 object-contain object-right-bottom"
        :class="[
          partner.id === GENITOR_PARTNER_ID ? 'h-3' : 'h-4',
          { 'md:m-3': big },
          { 'md:h-4': big && partner.id === GENITOR_PARTNER_ID },
          { 'md:h-5': big && partner.id !== GENITOR_PARTNER_ID },
        ]"
        alt="partner logo"
        width="80"
      />
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { JobAdListDto } from '~/typesAuto/jobshot/v1'
import { tagTypes, GENITOR_PARTNER_ID } from '~/api/jobs'

const dataLayer = useDataLayer()

const props = defineProps<{
  job: JobAdListDto
  placement?: string
  big?: boolean
}>()

const { title, advertiser, location, jobAdTags, partner, slug } = props.job

const isJobOfTheDay = computed(() =>
  jobAdTags?.find((jobAdTag) => jobAdTag.tag?.id === tagTypes.jobOfTheDay)
)
const isHighlighted = computed(
  () =>
    jobAdTags?.length &&
    jobAdTags.some((tag) => tag.tag?.id === tagTypes.highlightedJob)
)
</script>

<style lang="scss" scoped>
.genitor-commercial-triangle {
  background: linear-gradient(
    345deg,
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0.1) 50%,
    rgba(0, 0, 255, 0) calc(50% + 1px),
    rgba(0, 0, 255, 0)
  );
}
</style>
