<template>
  <div
    :class="[
      { container: link },
      false ? 'mb-10 mt-10' : 'mb-5 mt-5 md:mt-10',
    ]"
    class="section-label-container scroll-mt-20"
  >
    <div
      v-if="titleOrTranslation === 'Val 2022'"
      class="relative justify-center flex"
    >
      <img
        :src="'https://legacy.altinget.se/images/val2022-header.png'"
        class=""
      />
    </div>
    <div v-else class="relative justify-center flex px-5 md:px-0">
      <div>
        <div
          class="text-center"
          :class="[
            paperColor ? 'font-bold' : '',
            { black: black },
            { white: white },
            size === 'large' ? 'section-label-large' : 'section-label',
          ]"
          :style="{ color: paperColor ?? '' }"
        >
          <img
            v-if="icon"
            :src="require(`~/assets/icons/${icon}`)"
            class="w-9 h-9 inline relative align-top"
          />
          <component :is="addHeaderTag ? 'h1' : 'span'">
            <NuxtLink
              v-if="linkOrTranslation"
              :to="
                (linkToPaper ? currentPaperUrl : '') + '/' + linkOrTranslation
              "
            >
              {{ titleOrTranslation }}
            </NuxtLink>
            <template v-else>
              {{ titleOrTranslation }}
            </template>
          </component>
        </div>
        <div v-if="subtitle" class="body-s text-center opacity-50 mt-2">
          <component :is="addHeaderTag ? 'h2' : 'span'">
            <NuxtLink
              v-if="linkOrTranslation"
              :to="
                (linkToPaper ? currentPaperUrl : '') + '/' + linkOrTranslation
              "
            >
              {{ subtitleOrTranslation }}
            </NuxtLink>
            <template v-else>
              {{ subtitleOrTranslation }}
            </template>
          </component>
        </div>
      </div>

      <NuxtLink
        v-if="link"
        :to="(linkToPaper ? currentPaperUrl : '') + '/' + linkOrTranslation"
        class="hidden absolute right-0 bottom-0 border whitespace-nowrap cursor-pointer md:inline-flex items-center transition-colors duration-100 select-none shrink-0 justify-center h-[36px] border-gray-300 bg-white hover:bg-gray-300 text-gray-700 hover:text-gray-900 px-4 py-2 rounded-full"
        :class="false ? 'button-m' : 'button-l'"
      >
        {{ $t(linkLabel) }}
      </NuxtLink>
    </div>
  </div>
</template>

<script setup lang="ts">
const nuxtApp = useNuxtApp()

const indexStore = useIndexStore()

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  link: {
    type: String,
    default: null,
  },
  linkLabel: {
    type: String,
    default: 'ShowAll',
  },
  size: {
    type: String,
    default: 'small', // small / large
  },
  paper: {
    type: Number,
    default: null,
  },
  linkToPaper: {
    type: Boolean,
    default: false,
  },
  black: {
    type: Boolean,
    default: false,
  },
  white: {
    type: Boolean,
    default: false,
  },
  addHeaderTag: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: '',
  },
})
const paperColor = computed(() => {
  if (props.paper) {
    const paper = indexStore.papers.find(
      (paper) => paper.RecordId === props.paper
    )
    if (paper) {
      return paper.ColorRGB
    } else {
      return null
    }
  } else {
    return null
  }
})
const currentPaperUrl = computed(() => {
  return indexStore.currentPaper?.RecordId === 1
    ? ''
    : `/${indexStore.currentPaper?.Url}`
})
const titleOrTranslation = computed(() => {
  if (props.title && nuxtApp.$te(props.title) && props.title) {
    return nuxtApp.$t(props.title)
  } else {
    return props.title
  }
})
const subtitleOrTranslation = computed(() => {
  if (props.subtitle && nuxtApp.$te(props.subtitle) && props.subtitle) {
    return nuxtApp.$t(props.subtitle)
  } else {
    return props.subtitle
  }
})

const linkOrTranslation = computed(() => {
  if (props.link && nuxtApp.$te(props.link) && props.link) {
    return nuxtApp.$t(props.link)
  } else {
    return props.link
  }
})
</script>
