<template>
  <NuxtLink
    v-if="item.slug"
    ref="jobTeaserRef"
    :style="
      item.partnerId === GENITOR_PARTNER_ID &&
      'background-image: url(https://legacy.altinget.dk/images/ads/jobbggrey.png); background-repeat: no-repeat; background-position-x: right; background-position-y: bottom; background-size: 165%;'
    "
    :to="{
      name: 'job',
      params: {
        id: item.slug,
      },
    }"
    class="flex h-56 md:h-70 mb-6 md:mb-16 shrink-0 max-w-full flex-col p-3 rounded-xl hover:my-1 hover:shadow-lg hover:bg-lightgray transition-all duration-200 relative"
    :class="isHighlighted ? 'border-blue border' : 'border-gray-300 border'"
    @click="
      placement &&
        dataLayer.job.listClick(
          item,
          placement,
          isHighlighted ? 'Highlighted' : 'Normal'
        )
    "
  >
    <NuxtImg
      loading="lazy"
      width="64"
      class="w-15 h-15 shrink-0 grow-0 object-contain object-center mr-4 rounded-md"
      fit="contain"
      :alt="item.title ?? undefined"
      :src="item.advertiser?.logoLink ?? undefined"
    />
    <div class="flex flex-col h-full">
      <div
        class="text-black line-clamp-3 list-title-xs !font-light mt-2"
        v-html="item.title"
      />
      <div class="list-label flex items-center mt-auto">
        <template v-if="!true">
          <img
            src="~/assets/icons/location.svg?url"
            class="w-4 h-4 inline-block mr-1 opacity-35"
            :alt="$t('Addresse')"
          /><span class="opacity-35" v-html="item.location?.name" />
        </template>
        <CommonButton
          v-if="isJobOfTheDay"
          bg="bg-blue"
          size="small"
          text="text-white"
          border="border-none"
          class="mr-1 ml-1"
        >
          {{ $t('JobOfTheDay') }}
        </CommonButton>
        <template v-if="!isJobOfTheDay">
          <img
            src="~/assets/icons/calendar.svg?url"
            :alt="$t('Routes.Calendar')"
            class="w-4 h-4 inline-block mr-1 ml-3 opacity-35"
          />
          <template v-if="endDate">
            <span class="opacity-35">{{ endDate }}</span>
          </template>
          <template v-else>
            <span class="opacity-35">{{ $t('JobAsap') }}</span>
          </template>
        </template>
        <img
          v-if="item.partner?.logoLink && item.partner.name"
          class="h-6 inline-block mr-6 mb-10 absolute bottom-0 right-0"
          :alt="item.partner.name"
          :src="item.partner.logoLink"
        />
      </div>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import { tagTypes, GENITOR_PARTNER_ID } from '~/api/jobs'
import type { JobAdListDto, JobAdTagListDto } from '~/typesAuto/jobshot/v1'

const dataLayer = useDataLayer()

const props = defineProps<{
  item: JobAdListDto
  placement?: string
}>()

const isHighlighted = computed(
  () =>
    props.item.jobAdTags?.length &&
    props.item.jobAdTags.some(
      (jobAdTag: JobAdTagListDto) =>
        jobAdTag.tag?.id === tagTypes.highlightedJob
    )
)
const isJobOfTheDay = computed(() =>
  props.item.jobAdTags?.find(
    (jobAdTag: JobAdTagListDto) => jobAdTag.tag?.id === tagTypes.jobOfTheDay
  )
)
const endDate = computed(
  () =>
    props.item.offlineTime &&
    formatDate(new Date(props.item.offlineTime), false)
)
</script>
